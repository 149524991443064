import { jsonVerifier } from "./verifyJson";
interface FrontierErrorResponse {
  id: string;
  status: string;
  code: string;
  title: string;
  detail: string;
  source: {
    pointer: string;
  };
}
const handleSuccess = async (
  responseJson: Response,
  setResponse: React.Dispatch<React.SetStateAction<string | undefined>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  setResponse(JSON.stringify(await responseJson.json(), null, 1));
  setErrorMessage(undefined);
};

const handleUnauthorizedException = (error: FrontierErrorResponse) => {
  //code of we ever want to handle different types of erros separately
};

const handleJsonError = (error: FrontierErrorResponse) => {
  //code of we ever want to handle different types of erros separately
};

const handleErrorResponse = async (
  error: any,
  setResponse: React.Dispatch<React.SetStateAction<string | undefined>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (error instanceof Response) {
    try {
      const errorJson = await error.json();
      if (errorJson) {
        const errorsArray = errorJson.errors;
        for (const error of errorsArray) {
          if (error.code === "RequestValidationError") {
            handleJsonError(error);
          } else {
            handleUnauthorizedException(error);
          }
        }
      }
      setErrorMessage(JSON.stringify(errorJson, null, 1));
    } catch (jsonError) {
      setErrorMessage(`Error parsing JSON response: ${jsonError}`);
    }
  } else {
    setErrorMessage(error.message);
  }
  setResponse(undefined);
};

export const handleVerifyButtonClick = async (
  json: string,
  setResponse: React.Dispatch<React.SetStateAction<string | undefined>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  getAccessTokenSilently: any
) => {
  try {
    const responseJson = await jsonVerifier(json, getAccessTokenSilently);
    handleSuccess(responseJson, setResponse, setErrorMessage);
    return;
  } catch (error: any) {
    handleErrorResponse(error, setResponse, setErrorMessage);
    return;
  }
};
