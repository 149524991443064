import styled from "styled-components";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HealthStatus from "../healthStatus";

interface StatusContainerProps {
  healthstatus: HealthStatus;
}

const StatusContainer = styled.div<StatusContainerProps>`
  background: white;
  border: ${(props) =>
    props.healthstatus === HealthStatus.Degraded ||
    props.healthstatus === HealthStatus.Loading
      ? "3px solid orange"
      : props.healthstatus === HealthStatus.Healthy
      ? "3px solid green"
      : "3px solid red"};
  color: black;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  width: 350px;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const HealthStatusTableContainer = styled.div`
  background: white;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  width: 800px;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const HealthCheckTableItemContainer = styled.div`
  padding: 10px;
  margin: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HealthCheckTableItemLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

const HealthStatusContainer = styled.div<StatusContainerProps>`
  background: ${(props) =>
    props.healthstatus === HealthStatus.Degraded ||
    props.healthstatus === HealthStatus.Loading
      ? "orange"
      : props.healthstatus === HealthStatus.Healthy
      ? "limegreen"
      : "red"};
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  width: 800px;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StatusTitle = styled.h2`
  margin: 0;
`;

const StatusIconCircle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: bottom;
`;

const ServiceUpIcon = styled(DoneIcon)`
  color: white;
`;

const ServiceDownIcon = styled(CloseIcon)`
  color: white;
`;

const styles = {
  HealthStatusTableContainer,
  HealthStatusContainer,
  HealthCheckTableItemContainer,
  HealthCheckTableItemLeftContainer,
  StatusContainer,
  StatusTitle,
  StatusIconCircle,
  ServiceUpIcon,
  ServiceDownIcon,
};

export default styles;
