import styled from "styled-components";

// todo globalise this
export const Section = styled.div`
  margin-bottom: 40px;
  width: 70%;
`;

// todo globalise this
export const HealthCheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const styles = { Section, HealthCheckWrapper };

export default styles;
