import { TextField } from "@mui/material";
import React from "react";

interface JSONEditorProps {
  value: string;
  handleUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const JSONEditor: React.FC<JSONEditorProps> = ({
  value,
  handleUpdate,
}) => {
  return (
    <TextField
      multiline
      rows={15}
      fullWidth
      value={value}
      onChange={handleUpdate}
    />
  );
};
