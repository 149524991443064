import React from "react";
import s from "./spinner.styles";

const Spinner: React.FC = () => {
  return (
    <s.SpinnerContainer data-testid="spinner-container">
      <s.SpinnerIcon data-testid="spinner-icon" />
    </s.SpinnerContainer>
  );
};

export default Spinner;
