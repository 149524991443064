import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

interface CustomAppState extends AppState {
  returnTo?: string; // Add other properties as needed
}

export const Auth0ProviderWithNavigate: React.FC<
  Auth0ProviderWithNavigateProps
> = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL || "";
  const audience = process.env.REACT_APP_PRODUCT_API_TIER_URL || "";

  const onRedirectCallback = (appState?: CustomAppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  useEffect(() => {
    if (!(domain && clientId && redirectUri && audience)) {
      navigate("/");
    }
  }, [domain, clientId, redirectUri, audience, navigate]);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
