import styled from "styled-components";
interface ResponseType {
  type: string | null;
}
const VerifyContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 5%;
  background-color: #ffffff;
`;

const SectionContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  gap: 10px;
  overflow: auto;
  height: auto;
  max-height: auto;
  text-align: center;
`;

const ResponseContainer = styled.div<ResponseType>`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  gap: 10px;
  overflow: auto;
  max-width: 80vh;
  max-height: 80vh;
  border: ${(props) =>
    props.type === "error"
      ? "3px solid orange"
      : props.type === "response"
      ? "3px solid green"
      : "3px solid grey"};
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
`;

const styles = { VerifyContainer, ResponseContainer, SectionContainer };

export default styles;
