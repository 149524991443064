const verifyJson = async (
  json: string,
  url: string,
  getAccessTokenSilently: any
) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${url}/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: json,
    });

    if (!response.ok) {
      throw response; // throw the entire response
    } else {
      return response;
    }
  } catch (error: any) {
    throw error;
  }
};

export const jsonVerifier = (json: string, getAccessTokenSilently: any) => {
  const apiUrl = "/track/v1/product";
  const fetchData = async () => {
    try {
      const result = await verifyJson(json, apiUrl, getAccessTokenSilently);
      return result;
    } catch (error) {
      throw error; // throw the entire response in case of an error
    }
  };
  return fetchData();
};
