import styled from "styled-components";

const StyledAuthButton = styled.button`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1em;
  padding: 0.5em 1.5em;
  border: 1px solid white;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  }
`;

const styles = { StyledAuthButton };

export default styles;
