import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import gs from "../globalStyles.styles";
import s from "./productTrackerPage.styles";
import { ProductTrackerComponent } from "../../components/productTracker/productTrackerComponent";
import { useProductTracker } from "../../functions/pollProducts/pollProducts";
import { ApiTierEvent } from "../../components/productTracker/productTrackerInterfaces";
import { useAuth0 } from "@auth0/auth0-react";
import ROUTE_MAP from "../../routes/routeMap";

const ProductTrackerPage: React.FC = () => {
  const [searchId, setSearchId] = useState<string>("");
  const [event, setEvent] = useState<ApiTierEvent | null>(null);
  const [eventStatus, setEventStatus] = useState<string | null>("refresh");
  const [saveClicked, setSaveClicked] = useState(false);

  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const { event_list } = useProductTracker(getAccessTokenSilently);
  const findEventBySearchId = (events: ApiTierEvent[], searchId: string) => {
    return events.find((event: ApiTierEvent) => {
      try {
        const parsedJson = JSON.parse(event?.entity);
        return parsedJson && parsedJson.name && parsedJson.name === searchId;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return false;
      }
    });
  };
  const handleSearch = () => {
    if (uuidRegex.test(searchId)) {
      const event_in_list = event_list.find(
        (event) => event.entity_id === searchId
      );
      if (event_in_list) {
        setEvent(event_in_list);

        setEventStatus(
          event_in_list.status === "SUCCESS" ? "Completed" : "Pending"
        );
      } else {
        setEvent(null);
        setEventStatus("Product ID does not exist");
      }
      setSaveClicked(false);
    } else {
      const event_in_list = findEventBySearchId(event_list, searchId);
      if (event_in_list) {
        setEvent(event_in_list);
        setEventStatus(
          event_in_list.status === "SUCCESS" ? "Completed" : "Pending"
        );
      } else {
        setEvent(null);
        setEventStatus("Product ID or Name does not exist");
      }
    }
  };

  console.log(event_list);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: ROUTE_MAP.PRODUCT_TRACKER },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <gs.PageContainer>
      <gs.Container>
        {isAuthenticated ? (
          <>
            <gs.Title>Product Tracker</gs.Title>
            <s.SearchContainer>
              <TextField
                label="Enter Product ID or Name"
                variant="outlined"
                value={searchId}
                onChange={(e) => setSearchId(e.target.value)}
                style={{ width: "200%" }}
              />
            </s.SearchContainer>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
            <ProductTrackerComponent
              event={event || null}
              status={eventStatus}
              setSaveClicked={setSaveClicked}
              saveClicked={saveClicked}
              getAccessTokenSilently={getAccessTokenSilently}
            />
          </>
        ) : (
          <p>Loading...</p>
        )}
      </gs.Container>
    </gs.PageContainer>
  );
};
export default ProductTrackerPage;
