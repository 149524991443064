import styled from "styled-components";
import { Link } from "react-router-dom";

const MenuBar = styled.div`
  flex: 1;
  width: 200px;
  background-color: #f8f8f8;
  position: fixed;
  top: 7%;
  left: 0;
  right: 0;
  z-index: 998;
  min-height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-right: 1px solid #dddddd;
`;

const CloseButton = styled.button`
  margin-top: 10px;
  padding-top: 2px;
  font-size: 16px;
  align: right;
  margin-left: 85%;
  border: none;
  background-color: #f8f8f8;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
`;

const MenuLink = styled(Link)`
  display: block;
  padding: 10px 20px;
  margin-left: 3px;
  color: #1e2425;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #eaeaea;
  }
`;

const Subtitle = styled.h2`
  font-size: 24px;
  color: #666666;
  margin-bottom: 32px;
`;

const styles = {
  MenuBar,
  Subtitle,
  MenuLink,
  CloseButton,
};

export default styles;
