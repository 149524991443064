import React, { useState, ChangeEvent, useEffect } from "react";
import { JSONEditor } from "../../components/jsonEditor/jsonEditor";
import gs from "../globalStyles.styles";
import { Button } from "@mui/material";
import s from "./productJsonVerifyPage.styles";
import { handleVerifyButtonClick } from "../../functions/apiVerify/jsonErrorAnalysis";
import { useAuth0 } from "@auth0/auth0-react";
import ROUTE_MAP from "../../routes/routeMap";

const ProductJsonVerifyPage: React.FC = () => {
  const [json, setJson] = useState<string>("");
  const [response, setResponse] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const handleUpdateEntitySnapshot = (e: ChangeEvent<HTMLInputElement>) => {
    setJson(e.target.value);
  };

  const handleButtonClickWrapper = () => {
    handleVerifyButtonClick(
      json,
      setResponse,
      setErrorMessage,
      getAccessTokenSilently
    );
  };
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: ROUTE_MAP.VERIFY_JSON },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);
  return (
    <gs.PageContainer>
      {isAuthenticated ? (
        <s.VerifyContainer>
          <s.SectionContainer>
            <gs.Title>Enter your product JSON</gs.Title>
            <JSONEditor
              value={json}
              handleUpdate={handleUpdateEntitySnapshot}
            />
            <Button
              disabled={!json}
              variant="contained"
              onClick={handleButtonClickWrapper}
            >
              Send Post Request
            </Button>
          </s.SectionContainer>
          {errorMessage || response ? (
            <>
              {errorMessage && (
                <s.ResponseContainer type="error">
                  Error: <pre>{errorMessage}</pre>
                </s.ResponseContainer>
              )}
              {response && (
                <s.ResponseContainer type="response">
                  Response: <pre>{response}</pre>
                </s.ResponseContainer>
              )}
            </>
          ) : (
            ""
          )}
        </s.VerifyContainer>
      ) : (
        <p>Loading...</p>
      )}
    </gs.PageContainer>
  );
};

export default ProductJsonVerifyPage;
