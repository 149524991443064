import { useAuth0 } from "@auth0/auth0-react";
import s from "./button.styles";
import React from "react";
import ROUTE_MAP from "../../routes/routeMap";

type Props = {
  successRedirect?: string;
};

const LoginButton: React.FC<Props> = ({
  successRedirect = ROUTE_MAP.HEALTH_CHECK,
}) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = (): void => {
    loginWithRedirect({
      appState: { returnTo: successRedirect },
    });
  };

  return (
    <div>
      <s.StyledAuthButton onClick={handleLogin}>Login</s.StyledAuthButton>
    </div>
  );
};

export default LoginButton;
