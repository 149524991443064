import ROUTE_MAP from "../../routes/routeMap";
import s from "./sideBar.styles";
import { GrClose } from "react-icons/gr";
import React from "react";

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SideBar({ isOpen, setIsOpen }: SideBarProps) {
  const handleToggleSidebar = () => {
    setIsOpen(false);
  };

  return isOpen ? (
    <s.MenuBar>
      <s.CloseButton onClick={handleToggleSidebar}>
        <GrClose />
      </s.CloseButton>
      <s.MenuLink to={ROUTE_MAP.HEALTH_CHECK}>Status Page</s.MenuLink>
      <s.MenuLink to={ROUTE_MAP.DOCUMENTATION}>API Documentation</s.MenuLink>
      <s.MenuLink to={ROUTE_MAP.PRODUCT_TRACKER}>Product Tracker</s.MenuLink>
      <s.MenuLink to={ROUTE_MAP.VERIFY_JSON}>Json Verifier</s.MenuLink>
    </s.MenuBar>
  ) : null;
}

export default SideBar;
