import { ApiTierEventProp } from "./productTrackerInterfaces";
import { useEffect, useState } from "react";
import { handleVerifyButtonClick } from "../../functions/apiVerify/jsonErrorAnalysis";
import productTrackerComponents from "./productTrackerComponents";
export const ProductTrackerComponent: React.FC<ApiTierEventProp> = ({
  event,
  status,
  saveClicked,
  setSaveClicked,
  getAccessTokenSilently,
}) => {
  const [entitySnapshot, setEntitySnapshot] = useState("");
  const [response, setResponse] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const parsedJson = event != null ? JSON.parse(event?.entity) : null;
    if (parsedJson != null) {
      setEntitySnapshot(JSON.stringify(parsedJson, null, 4));
    }
  }, [event]);

  const handleSubmitUpdate = async () => {
    console.log("handleSubmitUpdate.");
  };

  const handleSaveClick = () => {
    handleVerifyButtonClick(
      entitySnapshot,
      setResponse,
      setErrorMessage,
      getAccessTokenSilently
    );
  };

  useEffect(() => {
    if (response) {
      setSaveClicked(true);
    } else {
      setSaveClicked(false);
    }
  }, [response, setSaveClicked]);

  const handleUpdateEntitySnapshot = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSaveClicked(false);
    setEntitySnapshot(e.target.value);
  };

  return (
    <>
      {event ? (
        <productTrackerComponents.ProductInfoComponent
          event={event}
          status={status}
          entitySnapshot={entitySnapshot}
          handleUpdateEntitySnapshot={handleUpdateEntitySnapshot}
          handleSaveClick={handleSaveClick}
          errorMessage={errorMessage}
          handleSubmitUpdate={handleSubmitUpdate}
          saveClicked={saveClicked}
        />
      ) : (
        status !== "refresh" && (
          <productTrackerComponents.MissingProductInfoComponent
            status={status}
          />
        )
      )}
    </>
  );
};
