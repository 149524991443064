// Route map to store centralised urls, easier to update in the future
const ROUTE_MAP = {
  HEALTH_CHECK: "/",
  PRODUCT_TRACKER: "/product-tracker",
  DOCUMENTATION: "/documentation",
  VERIFY_JSON: "/json-verifier",
  DOCUMENTATION_REDIRECT:
    "https://api-documentation-web-app.front.icekube.ics.cloud/",
};

export default ROUTE_MAP;
