import React from "react";
import "./App.css";
import Header from "./components/header/header";
import { Route, Routes } from "react-router-dom";
import HealthCheckPage from "./pages/healthCheck/healthCheck";
import s from "./App.styles";
import RedirectPage from "./pages/redirectPage/redirectPage";
import SideBar from "./components/sideBar/sideBar";
import { HealthCheckProvider } from "./contexts/healthCheckContext";
import ProductTrackerPage from "./pages/productTrackerPage/productTrackerPage";
import ROUTE_MAP from "./routes/routeMap";
import ProductJsonVerifyPage from "./pages/productJsonVerifyPage/productJsonVerifyPage";
const App: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  return (
    <div>
      <Header setIsSidebarOpen={setIsSidebarOpen} />
      <s.AppContainer>
        <s.SidebarContainer isOpen={isSidebarOpen}>
          {isSidebarOpen && (
            <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          )}
        </s.SidebarContainer>
        <HealthCheckProvider>
          <s.ContentContainer>
            <Routes>
              <Route
                path={ROUTE_MAP.HEALTH_CHECK}
                element={<HealthCheckPage />}
              />

              <Route
                path={ROUTE_MAP.DOCUMENTATION}
                element={
                  <RedirectPage url={ROUTE_MAP.DOCUMENTATION_REDIRECT} />
                }
              />
              <Route
                path={ROUTE_MAP.PRODUCT_TRACKER}
                element={<ProductTrackerPage />}
              />
              <Route
                path={ROUTE_MAP.VERIFY_JSON}
                element={<ProductJsonVerifyPage />}
              />
            </Routes>
          </s.ContentContainer>
        </HealthCheckProvider>
      </s.AppContainer>
    </div>
  );
};

export default App;
