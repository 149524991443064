import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./loginButton";
import LogoutButton from "./logoutButton";
import ROUTE_MAP from "../../routes/routeMap";

type Props = {
  successRedirect?: string;
};

const AuthButton: React.FC<Props> = ({
  successRedirect = ROUTE_MAP.HEALTH_CHECK,
}) => {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return <LogoutButton></LogoutButton>;
  }
  return <LoginButton successRedirect={successRedirect} />;
};

export default AuthButton;
