import styled from "styled-components";
interface StatusContainerProps {
  status: string | null;
}
const ProductContainer = styled.div<StatusContainerProps>`
  background: white;
  border: ${(props) =>
    props.status === "Pending"
      ? "3px solid orange"
      : props.status === "Completed"
      ? "3px solid green"
      : "3px solid grey"};

  color: black;
  padding: 30px;
  border-radius: 10px;
  width: 60%;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
`;

const MissingProductContainer = styled.div<StatusContainerProps>`
  background: white;
  border: 3px solid gray;
  display: flex;
  color: black;
  padding: 30px;
  border-radius: 10px;
  width: 60%;
  margin: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
`;

const B = styled.b`
  font-size: 16px;
  text-align: center;
  margin: auto;
  align-items: center;
  display: flex;
  padding: 2px;
`;

const PrettyJson = styled.pre`
  padding: 8px;
  margin: 0;
  white-space: pre;
  align-self: start;
  display: flex;
`;
const ErrorMessageContainer = styled.pre`
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 3px solid red;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
`;
const styles = {
  ProductContainer,
  B,
  PrettyJson,
  MissingProductContainer,
  ErrorMessageContainer,
};

export default styles;
