import React, { useEffect, useState } from "react";
import s from "./incidentReport.styles";
import {
  getStatusFromAll,
  useHealthCheck,
} from "../../../contexts/healthCheckContext";
import gs from "../../../pages/globalStyles.styles";
import HealthStatus from "../healthStatus";

const IncidentComponent: React.FC = () => {
  const getIncidentData = () => {
    const incident = {
      message:
        "We are currently experiencing difficulties. A member of our team is investigating this issue now.",
    };

    return incident;
  };

  const incident = getIncidentData();

  const healthStatuses = useHealthCheck();
  const [allHealthStatus, setAllHealthStatus] = useState<HealthStatus>(
    getStatusFromAll(healthStatuses)
  );

  useEffect(() => {
    setAllHealthStatus(getStatusFromAll(healthStatuses));
  }, [healthStatuses]);

  if (
    allHealthStatus !== HealthStatus.Healthy &&
    allHealthStatus !== HealthStatus.Loading
  ) {
    return (
      <s.IncidentReportContainer>
        <gs.Subtitle>Incident Update</gs.Subtitle>
        <gs.Text>{incident.message}</gs.Text>
      </s.IncidentReportContainer>
    );
  } else {
    return <></>;
  }
};

export default IncidentComponent;
